













































































































































































import { Component, Vue, Prop, Mixins } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";

import { HollowDotsSpinner } from "epic-spinners";
import Axios from "axios";
import stores from "@/store/store";

const store: any = stores;

@Component({ components: { HollowDotsSpinner } })
export default class LetsDoIt extends Vue {
  @Prop()
  public showDocReqPopup: boolean;

  public dontAsk = false;
  public source: any = null;
  /***************************************************************************
   *             On Click of Dont Ask it again                               *
   ***************************************************************************/
  public async saveDontAsk() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "loan/saveDontAsk",
        {
          userId: this.$store.state.sessionObject.userId,
          dontAsk: this.dontAsk
        });
    } catch (error) {
      console.log(error);
    }
  }

  public closeModal() {
    this.$modal.hide("documentRequestModal");
    if (this.$route.query.source == "Add Fnm Borrower")
      this.$router.push("/dashboard"); 
    else {
      
      this.$router.back();
      }
  }

  mounted() {
    let modalName: any = this.$route.query.modalName;
    this.source = this.$route.query.source;
    this.$modal.show(modalName);
  }
}
